// i18next-extract-mark-ns-start markets-motorsport-hybrid

import { MARKETS_CAPABILITIES_ELECTRIC_NAV, MOTORSPORT_NAV, HYBRID_ELECTRIFICATION_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorsportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Engine, battery, motor and control parameter simulation</h2>
						<p>
							Tough new emissions regulations and the need for maximum efficiency have driven the emergence of hybrid racing machines which use innovative designs
							to link Internal Combustion (IC) engines and electrical power devices.
							For example, combining conventional powertrains with electrical units and batteries to recover ‘lost’ energy from heat and kinetics for reuse in the system.
							This results in a vehicle delivering improved performance, range and top speed from the engine
							whilst redeploying stored energy as immediately available power when required.
						</p>
						<p>
							This however, leaves teams and manufacturers with the challenge of simultaneously developing smaller Internal Combustion (IC) engines for hybrid systems and
							compatible battery / motor units, all with tighter resources and budgets.
						</p>
						<p>
							In the initial stages of development physical prototype modelling is expensive,
							requiring virtual simulation tools to answer many of the 'What ifs' before committing to a design path.
							Complete vehicle system simulation models are vital in the selection of the correct balance of components
							to meet performance, emissions, weight cost drivability requirements for the both the IC engine and
							electric hybrid systems by controlling and predicting their behaviour under different duty cycles.
						</p>
						<p>
							Hybrid electric motorsport is now well established across Single Seaters, Endurance, Off-Road, and
							Touring Cars – all of which can be modelled using simulation software.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORSPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_ELECTRIC_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{HYBRID_ELECTRIFICATION_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<p> <small>Photo: motorsportimages.com</small></p>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorsportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorsport-hybrid", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorsport/MotorsportImages_1920x577_F1_cropped.jpg" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { capabilities: { in: "Electrification" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
